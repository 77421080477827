@import "variables";

%progress-bar {
  mat-progress-bar {
    .mat-progress-bar-buffer {
      background: $lavender;
    }

    .mat-progress-bar-fill {
      background-color: $lavender;

      &:after {
        background-color: $prussian-blue;
      }
    }
  }
}
