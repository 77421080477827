@import "variables";
@import "mixins";

.mat-drawer-container {
  color: $prussian-blue;
}

.mat-card {
  font-family: inherit;
}

a {
  color: inherit;
}

button {
}

nav {
}

h1 {
  font-weight: 600;
  font-size: $font-size-large;
  line-height: 1;
  margin-bottom: 50px;

  @include break-down(tablet-sm) {
    font-size: $font-size-large;
    margin-bottom: 50px;
  }

  &.center {
    text-align: center;
  }
}

h2 {
  font-size: $font-size-x-large;
  font-weight: 520;
  text-align: center;
}

h3 {
  font-size: $font-size-xx-large;
  font-weight: 400;
}

table {
  font-size: $font-size-x-small;

  .mat-header-row .mat-header-cell {
    font-weight: 600;
  }
}

.info-label {
  font-size: $font-size-small;
  font-weight: bold;
}

mat-icon {
  color: inherit;
}

button {
  color: inherit;
}

.background-image {
  background-position: center;
  display: block;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
