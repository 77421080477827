@import 'variables';

%mat-snack-bar {
  .mat-snack-bar-container {
    margin-top: 24px;
    margin-right: 24px;

    .mat-icon {
      min-width: 24px;
      margin-right: 10px;
    }

    &.success {
      color: $white;
      background-color: $fruit-salad;
    }

    &.error {
      color: $white;
      background-color: $persian-red;
    }

    &.warning {
      color: $white;
      background-color: $dark-orange;
    }

    &.info {
      color: $white;
      background-color: $pelorous;
    }
  }
}
