@use '@angular/material' as mat;

@include mat.core();

@import '../themes/derome-theme.scss';
@import '../themes/moelven-theme';
@import '../themes/abkarlhedin-theme';
@import '../themes/jga-theme';
@import '../themes/conifersoft-theme';

.derome-theme {
  @include mat.button-theme($sh-derome-theme);
  @include mat.tabs-theme($sh-derome-theme);
  @include mat.chips-theme($sh-derome-theme);
  @include mat.checkbox-theme($sh-derome-theme);
  @include mat.icon-theme($sh-derome-theme);
  @include mat.datepicker-theme($sh-derome-theme);
  @include mat.all-component-typographies($sh-derome-typography);
}

.moelven-theme {
  @include mat.button-theme($sh-moelven-theme);
  @include mat.tabs-theme($sh-moelven-theme);
  @include mat.chips-theme($sh-moelven-theme);
  @include mat.checkbox-theme($sh-moelven-theme);
  @include mat.icon-theme($sh-moelven-theme);
  @include mat.datepicker-theme($sh-moelven-theme);
  @include mat.all-component-typographies($sh-moelven-typography);
}

.abkarlhedin-theme {
  @include mat.button-theme($sh-abkarlhedin-theme);
  @include mat.tabs-theme($sh-abkarlhedin-theme);
  @include mat.chips-theme($sh-abkarlhedin-theme);
  @include mat.checkbox-theme($sh-abkarlhedin-theme);
  @include mat.icon-theme($sh-abkarlhedin-theme);
  @include mat.datepicker-theme($sh-abkarlhedin-theme);
  @include mat.all-component-typographies($sh-abkarlhedin-typography);
}

.jga-theme {
  @include mat.button-theme($sh-jga-theme);
  @include mat.tabs-theme($sh-jga-theme);
  @include mat.chips-theme($sh-jga-theme);
  @include mat.checkbox-theme($sh-jga-theme);
  @include mat.icon-theme($sh-jga-theme);
  @include mat.datepicker-theme($sh-jga-theme);
  @include mat.all-component-typographies($sh-jga-typography);
}

.conifersoft-theme {
  @include mat.button-theme($sh-conifersoft-theme);
  @include mat.tabs-theme($sh-conifersoft-theme);
  @include mat.chips-theme($sh-conifersoft-theme);
  @include mat.checkbox-theme($sh-conifersoft-theme);
  @include mat.icon-theme($sh-conifersoft-theme);
  @include mat.datepicker-theme($sh-conifersoft-theme);
  @include mat.all-component-typographies($sh-conifersoft-typography);
}

@import './_variables';
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Derome';
  font-weight: 300;
  src: url('./assets/fonts/derome/Derome-Regular.woff'), url('./assets/fonts/derome/Derome-Regular.woff2');
}

@font-face {
  font-family: 'Derome';
  font-weight: 100;
  src: url('./assets/fonts/derome/Derome-Light.woff'), url('./assets/fonts/derome/Derome-Light.woff2');
}

@font-face {
  font-family: 'Derome';
  font-weight: 600;
  src: url('./assets/fonts/derome/Derome-Bold.woff'), url('./assets/fonts/derome/Derome-Bold.woff2');
}

body,
html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

body {
  font-size: $font-size-small;

  .moelven-theme {
    font-family: $moelven-font-family;
  }

  .derome-theme {
    font-family: $derome-font-family;
  }

  .abkarlhedin-theme {
    font-family: $abkarlhedin-font-family;
  }

  .jga-theme {
    font-family: $jga-font-family;
  }

  .conifersoft-theme {
    font-family: $conifersoft-font-family;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none;
}

.mat-drawer-container {
  background: $white;
  overflow: visible;
}

.sidenav-component {
  height: 100%;
  padding: 25px 30px 25px;
  display: flex;
  flex-direction: column;
}

@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/typography';
@import 'styles/tables';
@import 'styles/content';
@import 'styles/carousel';
@import 'styles/utils';

@include break-down(tablet-md) {
  .mat-tab-links {
    justify-content: space-around;
  }
}

@include break-down(tablet-md) {
  .mat-tab-label {
    min-width: 50px !important;
  }
}

.mat-tab-body.mat-tab-body-active {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden !important;
  z-index: 1;
  flex-grow: 1;
}

.ngx-mat-color-toggle button {
  border: 1px solid #ddd!important;
}

.confirmation-modal {
  .mat-dialog-container {
    position: relative;
    width: 350px;
    overflow: hidden;

    .close-modal {
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      height: 60px;
      padding: 0;
    }

    .mat-dialog-actions {
      justify-content: flex-end;
      margin-top: 20px;
      margin-bottom: 0;
      padding: 0;
      min-height: unset;

      button {
        width: 100px;
      }
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $heather;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: $heather;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: $shark;
}
