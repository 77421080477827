@import "variables";

$table-row-hover: rgba(232, 233, 243, 0.57);

table:not(.mat-calendar-table),
.mat-table {
  width: 100%;
  border: 2px solid $table-border-color;
  font-family: inherit;

  .mat-header-row,
  .mat-header-cell {
    background: $table-header-bg-color;

    th {
      position: relative;

      mat-form-field {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 80%;
        height: 40px;
        margin-bottom: 10px;
      }

      .filter-button {
        position: absolute;
        cursor: pointer;
        top: -35px;
        right: 0;
        border: none;
        background: transparent;
      }
    }
  }

  .mat-row:last-of-type .mat-cell {
    border-bottom: none;
  }

  .mat-row {
    transition: 0.3s all;

    &:hover {
      background-color: $table-row-hover;
    }
  }

  .mat-cell,
  .mat-header-cell,
  .mat-footer-cell {
    color: inherit;
    font-size: inherit;
  }

  .partowner-row {
    & > td:last-child:after {
      content: '';
      width: 25px;
      display: inline-block;
      height: 20px;
      float: right;
      background: url('/../assets/icons/group.svg') right center;
    }
  }
}
