@import "variables";
@import "mixins";

%mat-button {
  .mat-calendar-content button {
    min-width: initial;
  }

  .mat-button-focus-overlay, .mat-button-ripple {
    z-index: -1 !important;
  }

  .mat-button-focus-overlay {
    display: none !important;
  }

  button {
    min-width: 100px;

    &.mat-button {
      &.mat-primary {
        &[disabled] {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }

    &.mat-stroked-button {
      &.mat-primary {
        &[disabled] {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }

    &.mat-raised-button {
      &.mat-primary {
        box-shadow: unset;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid transparent;
          box-shadow: unset;
        }

        &:active {
          border: 1px solid transparent;
          box-shadow: unset;
        }

        &[disabled] {
          border: 1px solid transparent;
          box-shadow: unset;
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }

    &.mat-mini-fab {
      &.mat-primary {
        box-shadow: 0 1px 4px 0 rgba(92, 122, 158, 0.39);
      }
    }

    &.small {
      font-size: $font-size-xx-small !important;
      padding: 6px 14px;
      line-height: 1;

      .mat-icon {
        @include icon-size($font-size-xx-small);
      }
    }

    &.medium {
      font-size: $font-size-x-small !important;;
      padding: 8px 16px;
      line-height: 1;

      .mat-icon {
        @include icon-size($font-size-x-small);
      }
    }

    &.large {
      font-size: $font-size-small !important;;
      padding: 10px 18px;
      line-height: 1;

      .mat-icon {
        @include icon-size($font-size-small);
      }
    }
  }

  a {
    &.mat-button {
      &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    &.mat-stroked-button {
      &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    &.mat-raised-button {
      box-shadow: unset;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid transparent;
        box-shadow: unset;
      }

      &:active {
        border: 1px solid transparent;
        box-shadow: unset;
      }

      &[disabled] {
        border: 1px solid transparent;
        box-shadow: unset;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    &.small {
      font-size: $font-size-xx-small !important;;
      padding: 6px 14px;
      line-height: 1;

      .mat-icon {
        @include icon-size($font-size-xx-small);
      }
    }

    &.medium {
      font-size: $font-size-x-small !important;;
      padding: 8px 16px;
      line-height: 1;

      .mat-icon {
        @include icon-size($font-size-x-small);
      }
    }

    &.large {
      font-size: $font-size-small !important;;
      padding: 10px 18px;
      line-height: 1;

      .mat-icon {
        @include icon-size($font-size-small);
      }
    }
  }

  .mat-button,
  .mat-stroked-button,
  .mat-raised-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    border-radius: 2px;
    font-weight: 400 !important;;

    .mat-button-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
