$prussian-blue: #063954;
$pelorous: #2F96B4;
$Shakespeare: #619FC3;
$malibu: #62b3e5;
$solitude: #e8eef9;
$black-haze: #e0ded8;
$floral-white: #fbfaf8;
$white: #FFFFFF;
$snow: #FAFAFA;
$lavender: #E8E9F3;
$gainsboro: #e0e0e0;
$very-light-grey: #ccc;
$light-gray: #d6d6d6;
$solitude: #EFF3FE;
$heather: #b2bec3;
$grey: #808080;
$dim-gray: #6b6b6b;
$matterhorn: #555555;
$night-rider: #333333;
$shark: #313541;
$nero: #212121;
$black: #000000;
$dark-orange: #F89406;
$very-light-red: #ff6f67;
$bright-red: #f44336;
$persian-red: #BD362F;
$fruit-salad: #51A351;
$islamic-green: #089000;
$dark-lime-green: #006d00;
$celery: #b1cd4e;

$abkarlhedin-primary-color: #0046ad;
$derome-primary-color: #328953;
$conifersoft-primary-color: #ffc701;
$jga-primary-color: #146343;
$jga-accent-color: #160616;
$moelven-primary-color: #0c373d;

$table-border-color: $lavender;
$table-header-bg-color: $floral-white;

$main-header-height: 60px;
$viewportHeight: calc(var(--vh, 1vh) * 100);
$content-height: calc(#{$viewportHeight} - #{$main-header-height});

$btn-transition-function: 0.2s ease-out;

$font-size-xxx-small: 10px;
$font-size-xx-small: 12px;
$font-size-x-small: 14px;
$font-size-small: 16px;
$font-size-xx-regular: 18px;
$font-size-x-regular: 20px;
$font-size-regular: 24px;
$font-size-xx-large: 26px;
$font-size-x-large: 30px;
$font-size-large: 36px;

$moelven-font-family: Helvetica, sans-serif;
$derome-font-family: Derome, sans-serif;
$abkarlhedin-font-family: Arial, sans-serif;
$jga-font-family: Source Sans Pro, sans-serif;
$conifersoft-font-family: Source Sans Pro, sans-serif;

$breakpoints: (
        tablet-sm: 500px,
        tablet-md: 768px,
        tablet-lg: 991px,
        desktop-sm: 1024px,
        desktop-md: 1200px,
        desktop-lg: 1440px
)
