@import "variables";
@import "mixins";

.item {
  padding: 10px;
  box-sizing: border-box;
}

.tile {
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.ngucarousel {
  .ngu-touch-container {
    overflow: hidden;
  }

  padding-left: 75px;
  padding-right: 75px;
}

.carousel-control {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  min-width: unset;
  height: 50px;
  border-radius: 999px;
  background: white;
  border: 2px solid $black-haze;
  color: $black-haze;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: $prussian-blue;
    border: 2px solid $prussian-blue;
  }
}

.leftRs {
  left: 0;
}

.rightRs {
  right: 0;
}

.myPoint {
  list-style-type: none;
  text-align: center;
  padding: 12px;
  margin: 0;
  white-space: nowrap;
  overflow: auto;
  box-sizing: border-box;
}

.myPoint li {
  display: inline-block;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.55);
  padding: 4px;
  margin: 0 3px;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition: 0.4s;
}

.myPoint li.active {
  background: $dim-gray;
  transform: scale(1.2);
}

@include break-down(tablet-md) {
  .leftRs,
  .rightRs {
    width: 30px;
    height: 30px;
  }

  .news-section {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 50px;
  }

  .ngucarousel {
    .ngu-touch-container {
      overflow: hidden;
    }

    padding-left: 40px;
    padding-right: 40px;
  }

  .tile {
    border: none;
  }
}
