@import "variables";
@import "mixins";

.content-container {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 90px 40px 40px 40px;
  background: $white;
  position: relative;
  box-sizing: border-box;

  &-start {
    margin: 0;

    h1.heading {
      text-align: start;
    }
  }

  mat-expansion-panel:first-child {
    margin-top: 20px;
  }

  mat-expansion-panel {
    margin-bottom: 10px;
  }
}

.mat-paginator.hide-paginator {
  display: none;
}

.html-container img {
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  object-fit: cover;
}

.html-container img + div {
  padding: 0 20px;
  @include multiLineEllipsis($lineHeight: 16px, $lineCount: 4);
}

@include break-between(tablet-md, desktop-lg) {
  .content-container {
    width: 100%;
  }
}

@include break-down(tablet-md) {
  .content-container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    overflow: auto;

    h1.heading {
      margin-bottom: 35px;
    }

    &-no-padding-bottom {
      padding-bottom: 0;
    }
  }

  .mat-tab-label {
    min-width: 50px !important;
  }
  .cdk-overlay-pane {
    max-width: 100vw !important;
    max-height: 100vh !important;
  }
}
