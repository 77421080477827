@import "variables";

%mat-select {
  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $black;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $black;
    font-weight: 500;
  }
  
  .mat-select-panel-wrap {

    .mat-select-panel {
      border: 1px solid $heather;
      min-width: 100% !important;
    }

    .operation-types {
      .mat-pseudo-checkbox-checked {
        background-color: $prussian-blue;
      }

      .mat-option.mat-selected:not(.mat-option-disabled) {
        color: $prussian-blue;
      }
    }
  }
}
