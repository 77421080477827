body .mat-snack-bar-container {
  margin-top: 24px;
  margin-right: 24px;
}
body .mat-snack-bar-container .mat-icon {
  min-width: 24px;
  margin-right: 10px;
}
body .mat-snack-bar-container.success {
  color: #FFFFFF;
  background-color: #51A351;
}
body .mat-snack-bar-container.error {
  color: #FFFFFF;
  background-color: #BD362F;
}
body .mat-snack-bar-container.warning {
  color: #FFFFFF;
  background-color: #F89406;
}
body .mat-snack-bar-container.info {
  color: #FFFFFF;
  background-color: #2F96B4;
}

body .mat-calendar-content button {
  min-width: initial;
}
body .mat-button-focus-overlay, body .mat-button-ripple {
  z-index: -1 !important;
}
body .mat-button-focus-overlay {
  display: none !important;
}
body button {
  min-width: 100px;
}
body button.mat-button.mat-primary[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
body button.mat-stroked-button.mat-primary[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
body button.mat-raised-button.mat-primary {
  box-shadow: unset;
  border: 1px solid transparent;
}
body button.mat-raised-button.mat-primary:hover {
  border: 1px solid transparent;
  box-shadow: unset;
}
body button.mat-raised-button.mat-primary:active {
  border: 1px solid transparent;
  box-shadow: unset;
}
body button.mat-raised-button.mat-primary[disabled] {
  border: 1px solid transparent;
  box-shadow: unset;
  cursor: not-allowed;
  opacity: 0.5;
}
body button.mat-mini-fab.mat-primary {
  box-shadow: 0 1px 4px 0 rgba(92, 122, 158, 0.39);
}
body button.small {
  font-size: 12px !important;
  padding: 6px 14px;
  line-height: 1;
}
body button.small .mat-icon {
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
}
body button.medium {
  font-size: 14px !important;
  padding: 8px 16px;
  line-height: 1;
}
body button.medium .mat-icon {
  width: 14px;
  height: 14px;
  font-size: 14px;
  line-height: 14px;
}
body button.large {
  font-size: 16px !important;
  padding: 10px 18px;
  line-height: 1;
}
body button.large .mat-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
}
body a.mat-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
body a.mat-stroked-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
body a.mat-raised-button {
  box-shadow: unset;
  border: 1px solid transparent;
}
body a.mat-raised-button:hover {
  border: 1px solid transparent;
  box-shadow: unset;
}
body a.mat-raised-button:active {
  border: 1px solid transparent;
  box-shadow: unset;
}
body a.mat-raised-button[disabled] {
  border: 1px solid transparent;
  box-shadow: unset;
  cursor: not-allowed;
  opacity: 0.5;
}
body a.small {
  font-size: 12px !important;
  padding: 6px 14px;
  line-height: 1;
}
body a.small .mat-icon {
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
}
body a.medium {
  font-size: 14px !important;
  padding: 8px 16px;
  line-height: 1;
}
body a.medium .mat-icon {
  width: 14px;
  height: 14px;
  font-size: 14px;
  line-height: 14px;
}
body a.large {
  font-size: 16px !important;
  padding: 10px 18px;
  line-height: 1;
}
body a.large .mat-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
}
body .mat-button,
body .mat-stroked-button,
body .mat-raised-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  border-radius: 2px;
  font-weight: 400 !important;
}
body .mat-button .mat-button-wrapper,
body .mat-stroked-button .mat-button-wrapper,
body .mat-raised-button .mat-button-wrapper {
  display: flex;
  align-items: center;
}

body mat-progress-bar .mat-progress-bar-buffer {
  background: #E8E9F3;
}
body mat-progress-bar .mat-progress-bar-fill {
  background-color: #E8E9F3;
}
body mat-progress-bar .mat-progress-bar-fill:after {
  background-color: #063954;
}

body .moelven-theme app-my-details-page .mat-tab-group .mat-tab-header {
  border-right: 2px solid #0c373d;
  border-bottom: unset;
}
body .moelven-theme app-my-details-page .mat-tab-group .mat-tab-header .mat-tab-labels .mat-tab-label {
  opacity: 0.7;
}
body .moelven-theme app-my-details-page .mat-tab-group .mat-tab-header .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background-color: #0c373d;
  color: #FFFFFF;
  opacity: 1;
}
body .derome-theme app-my-details-page .mat-tab-group .mat-tab-header {
  border-right: 2px solid #328953;
  border-bottom: unset;
}
body .derome-theme app-my-details-page .mat-tab-group .mat-tab-header .mat-tab-labels .mat-tab-label {
  opacity: 0.7;
}
body .derome-theme app-my-details-page .mat-tab-group .mat-tab-header .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background-color: #328953;
  color: #FFFFFF;
  opacity: 1;
}
body .abkarlhedin-theme app-my-details-page .mat-tab-group .mat-tab-header {
  border-right: 2px solid #0046ad;
  border-bottom: unset;
}
body .abkarlhedin-theme app-my-details-page .mat-tab-group .mat-tab-header .mat-tab-labels .mat-tab-label {
  opacity: 0.7;
}
body .abkarlhedin-theme app-my-details-page .mat-tab-group .mat-tab-header .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background-color: #0046ad;
  color: #FFFFFF;
  opacity: 1;
}
body .jga-theme app-my-details-page .mat-tab-group.mat-primary .mat-tab-header {
  border-right: 2px solid #146343;
  border-bottom: unset;
}
body .jga-theme app-my-details-page .mat-tab-group.mat-primary .mat-tab-header .mat-tab-labels .mat-tab-label {
  opacity: 0.7;
}
body .jga-theme app-my-details-page .mat-tab-group.mat-primary .mat-tab-header .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background-color: #146343;
  color: #FFFFFF;
  opacity: 1;
}
body .jga-theme app-my-details-page .mat-tab-group.mat-accent .mat-tab-header {
  border-right: 2px solid #160616;
  border-bottom: unset;
}
body .jga-theme app-my-details-page .mat-tab-group.mat-accent .mat-tab-header .mat-tab-labels .mat-tab-label {
  opacity: 0.7;
}
body .jga-theme app-my-details-page .mat-tab-group.mat-accent .mat-tab-header .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background-color: #160616;
  color: #FFFFFF;
  opacity: 1;
}
body .jga-theme .mat-tab-label {
  color: #160616;
}
body .conifersoft-theme app-my-details-page .mat-tab-group .mat-tab-header {
  border-right: 2px solid #ffc701;
  border-bottom: unset;
}
body .conifersoft-theme app-my-details-page .mat-tab-group .mat-tab-header .mat-tab-labels .mat-tab-label {
  opacity: 0.7;
}
body .conifersoft-theme app-my-details-page .mat-tab-group .mat-tab-header .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background-color: #ffc701;
  color: #FFFFFF;
  opacity: 1;
}
body .mat-tab-group .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
body .mat-tab-group .mat-tab-label {
  font-size: 20px !important;
}
body .mat-tab-group .mat-tab-label .tab-label-content {
  display: flex;
  align-items: center;
}
body .mat-tab-group .mat-tab-label .tab-label-content .mat-icon {
  color: inherit;
}
body .mat-tab-group .mat-tab-label .mat-icon {
  margin-right: 5px;
  width: 30px;
  height: 30px;
  font-size: 30px;
  line-height: 30px;
}
body .mat-tab-group .mat-tab-label-active {
  opacity: 1;
}

body .jga-theme .mat-accent .mat-tab-link {
  color: #160616;
}
body .mat-tab-nav-bar {
  margin-bottom: 10px;
}
body .mat-tab-nav-bar .mat-tab-link {
  font-size: 20px !important;
}
body .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active {
  opacity: 1;
}
body .mat-tab-nav-bar .mat-tab-link .mat-icon {
  margin-right: 5px;
  width: 30px;
  height: 30px;
  font-size: 30px;
  line-height: 30px;
}
body .mat-tab-nav-bar.mat-accent {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
body .mat-tab-nav-bar.mat-primary {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

body .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #000000;
}
body .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #000000;
  font-weight: 500;
}
body .mat-select-panel-wrap .mat-select-panel {
  border: 1px solid #b2bec3;
  min-width: 100% !important;
}
body .mat-select-panel-wrap .operation-types .mat-pseudo-checkbox-checked {
  background-color: #063954;
}
body .mat-select-panel-wrap .operation-types .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #063954;
}

body .auth-content .mat-form-field.mat-focused .mat-form-field-label,
body .auth-content .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #FFFFFF;
}
body .auth-content .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #FFFFFF;
}
body .auth-content .mat-form-field-label {
  color: #FFFFFF;
}
body .auth-content .mat-form-field .mat-input-element {
  line-height: 14px;
}
body .auth-content .mat-form-field:not(.mat-form-field-invalid) .mat-input-element {
  caret-color: #FFFFFF;
}
body .auth-content .mat-checkbox-frame {
  border-color: #FFFFFF;
}
body .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #000000;
}
body .mat-form-field.mat-focused .mat-form-field-label {
  color: #000000;
}
body .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #000000;
}

body .mat-tooltip {
  font-size: 14px !important;
  max-width: 280px !important;
}