@import "variables";

.ngx-mat-color-canvas .row {
  flex-wrap: nowrap;
  margin: 0;

  & > * {
    padding: 0;
    margin: 0;
    flex-shrink: 1;
  }
}

.ngx-mat-color-collection {
  .row {
    flex-wrap: nowrap;
    margin: 0;

    & > * {
      padding: 0;
      margin: 0;
      flex-shrink: 1;
    }

    &:first-child {
      margin-bottom: 5px;
    }
  }

  .btn-color {
    border: 1px solid $grey;
    box-sizing: content-box;
  }
}
