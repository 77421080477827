@import "variables";
@import "mixins";

%mat-tabs {
  .moelven-theme {
    app-my-details-page {
      .mat-tab-group .mat-tab-header {
        border-right: 2px solid $moelven-primary-color;
        border-bottom: unset;

        .mat-tab-labels .mat-tab-label {
          opacity: 0.7;

          &.mat-tab-label-active {
            background-color: $moelven-primary-color;
            color: $white;
            opacity: 1;
          }
        }
      }
    }
  }

  .derome-theme {
    app-my-details-page {
      .mat-tab-group .mat-tab-header {
        border-right: 2px solid $derome-primary-color;
        border-bottom: unset;

        .mat-tab-labels .mat-tab-label {
          opacity: 0.7;

          &.mat-tab-label-active {
            background-color: $derome-primary-color;
            color: $white;
            opacity: 1;
          }
        }
      }
    }
  }

  .abkarlhedin-theme {
    app-my-details-page {
      .mat-tab-group .mat-tab-header {
        border-right: 2px solid $abkarlhedin-primary-color;
        border-bottom: unset;

        .mat-tab-labels .mat-tab-label {
          opacity: 0.7;

          &.mat-tab-label-active {
            background-color: $abkarlhedin-primary-color;
            color: $white;
            opacity: 1;
          }
        }
      }
    }
  }

  .jga-theme {
    app-my-details-page {
      .mat-tab-group {
        &.mat-primary {
          .mat-tab-header {
            border-right: 2px solid $jga-primary-color;
            border-bottom: unset;

            .mat-tab-labels .mat-tab-label {
              opacity: 0.7;

              &.mat-tab-label-active {
                background-color: $jga-primary-color;
                color: $white;
                opacity: 1;
              }
            }
          }
        }

        &.mat-accent {
          .mat-tab-header {
            border-right: 2px solid $jga-accent-color;
            border-bottom: unset;

            .mat-tab-labels .mat-tab-label {
              opacity: 0.7;

              &.mat-tab-label-active {
                background-color: $jga-accent-color;
                color: $white;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .mat-tab-label {
      color: $jga-accent-color;
    }
  }

  .conifersoft-theme {
    app-my-details-page {
      .mat-tab-group .mat-tab-header {
        border-right: 2px solid $conifersoft-primary-color;
        border-bottom: unset;

        .mat-tab-labels .mat-tab-label {
          opacity: 0.7;

          &.mat-tab-label-active {
            background-color: $conifersoft-primary-color;
            color: $white;
            opacity: 1;
          }
        }
      }
    }
  }

  .mat-tab-group {
    .mat-tab-header {
      border-bottom: 1px solid rgba(0, 0, 0, .12);
    }

    .mat-tab-label {
      font-size: 20px !important;

      .tab-label-content {
        display: flex;
        align-items: center;

        .mat-icon {
          color: inherit;
        }
      }

      .mat-icon {
        margin-right: 5px;
        @include icon-size(30px);
      }

      &-active {
        opacity: 1;
      }
    }
  }
}

%mat-tabs-links {
  .jga-theme {
    .mat-accent .mat-tab-link {
      color: $jga-accent-color;
    }
  }

  .mat-tab-nav-bar {
    margin-bottom: 10px;

    .mat-tab-link {
      font-size: 20px !important;

      &.mat-tab-label-active {
        opacity: 1;
      }

      .mat-icon {
        margin-right: 5px;
        @include icon-size(30px);
      }
    }

    &.mat-accent {
      border-bottom: 1px solid rgba(0, 0, 0, .12);
    }

    &.mat-primary {
      border-bottom: 1px solid rgba(0, 0, 0, .12);
    }
  }
}
