@import "variables";

%mat-form-field {
  .auth-content {
    .mat-form-field:not(.mat-form-field-invalid).mat-focused .mat-form-field-label,
    .mat-form-field.mat-focused .mat-form-field-label,
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
      color: $white;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: $white;
    }

    .mat-form-field-label {
      color: $white;
    }

    .mat-form-field .mat-input-element {
      line-height: 14px;
    }

    .mat-form-field:not(.mat-form-field-invalid) .mat-input-element {
      caret-color: $white;
    }

    .mat-checkbox-frame {
      border-color: $white;
    }
  }

  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: $black;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: $black;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $black;
  }
}
